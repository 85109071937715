import React, {Component} from 'react';
import MediaQuery from 'react-responsive';
import {smoothScrollTo} from "../../util/ui/scrollto";
import Page from '../Page/Page';

const scrollToContentTargetId = 'home-content';

class Home extends Component {
    componentDidMount() {
        document.title = "Service Connect";

        this.tileFocus = this.tileFocus.bind(this);
        this.tileBlur = this.tileBlur.bind(this);
    }

    scrollToContent(e) {
        e.preventDefault();

        let target = document.getElementById(scrollToContentTargetId);
        if (target && target.offsetTop) {
            smoothScrollTo(target.offsetTop, false, 30);
        }
    }

    tileFocus(e) {
        if (e.target && e.target.parentElement && e.target.parentElement.classList.contains('block-item-content')) {
            e.target.parentElement.classList.add('active');
        }
    }

    tileBlur(e) {
        if (e.target && e.target.parentElement && e.target.parentElement.classList.contains('block-item-content')) {
            e.target.parentElement.classList.remove('active');
        }
    }

    render() {

        return (
            <Page>
                <div className="main-container">
                    <div className="hero-block">
                        <div className="wrapper">
                            <div className="hero-content">
                                <h1>Connecting you to the services you need</h1>
                                <p>Service Connect is an Australian Government digital platform that connects
                                    Australians with accredited service providers.</p>
                                <div>
                                    <button href="#home-content"
                                       className="anchor-link"
                                       aria-label="Skip to content"
                                       onClick={(e) => {
                                           this.scrollToContent(e)
                                       }}
                                       onKeyPress={(e) => {
                                           if (e.key === 'Enter') {
                                               this.scrollToContent(e)
                                           }
                                       }}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="home-content" id="home-content">
                        <div className="wrapper">
                            <h2>Programs</h2>
                            <div className="home-blocks">
                                <div className="blocks-row">
                                    <MediaQuery minWidth={768}>
                                        <div className="home-block-item recovery">
                                            <div className="item-wrapper">
                                                <div className="block-item-image">
                                                    <img className="item-image" src={"/assets/img/landing-hero.jpg"} alt=">Recovery Connect" aria-hidden="true"/>
                                                </div>
                                                <div className="block-item-info">
                                                    <img className="block-item-logo" src={"/assets/img/Logo_Recovery_Connect.svg"}
                                                        alt="Find disaster recovery assistance near you"/>
                                                    <h3>Disaster recovery assistance</h3>
                                                </div>
                                                <div className="block-item-content">
                                                    <h3>Disaster recovery assistance</h3>
                                                    <p>Recovery Connect helps you find payments and support during and after a major disaster.</p>
                                                    {/* eslint-disable react/jsx-no-target-blank */}
                                                    <a href="https://recovery.serviceconnect.gov.au/" role="button"
                                                    className="button" onFocus={this.tileFocus} onBlur={this.tileBlur} target={'_blank'}>Visit
                                                        Recovery Connect</a>
                                                    {/* eslint-enable react/jsx-no-target-blank */}
                                                </div>
                                            </div>
                                        </div>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={767}>
                                        <div className="home-block-item recovery">
                                            <div className="item-wrapper">
                                                {/* eslint-disable react/jsx-no-target-blank */}
                                                <a href="https://recovery.serviceconnect.gov.au/" target={'_blank'}>
                                                    <div className="block-item-image">
                                                        <img className="item-image" src={"/assets/img/landing-hero.jpg"} alt="" aria-hidden="true"/>
                                                    </div>
                                                </a>
                                                {/* eslint-enable react/jsx-no-target-blank */}
                                                <div className="block-item-info">
                                                    <img className="block-item-logo" src={"/assets/img/Logo_Recovery_Connect.svg"} alt="Find disaster recovery assistance near you"/>
                                                    <h3>Disaster recovery assistance</h3>
                                                    <p>Recovery Connect helps you find payments and support during and after a major disaster.</p>
                                                    {/* eslint-disable react/jsx-no-target-blank */}
                                                    <a href="https://recovery.serviceconnect.gov.au/" role="button" className="button" onFocus={this.tileFocus} onBlur={this.tileBlur} target={'_blank'}>Visit Recovery Connect</a>
                                                    {/* eslint-enable react/jsx-no-target-blank */}
                                                </div>
                                            </div>
                                        </div>
                                    </MediaQuery>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}

export default Home;
