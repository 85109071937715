import React, {Component} from 'react';
// import { Link } from 'react-router-dom';


class Footer extends Component {
    constructor(props) {
        super(props);

        this.resetTabbing = this.resetTabbing.bind(this);
    }

    resetTabbing() {
        document.querySelector('.logo-center a').focus();
    }

    render() {

        return (
            <footer>
                <div className="wrapper">
                    <div className="footer-container">
                        <div className="footer-left">
                            <span>Australian Government</span>
                        </div>
                        <div className="footer-right">
                            <ul className="footer-menu">
                                {/* eslint-disable react/jsx-no-target-blank */}
                                <li><a href="https://www.servicesaustralia.gov.au/individuals/accessibility" target={'_blank'}>Accessibility</a></li>
                                <li><a href="https://www.servicesaustralia.gov.au/individuals/contact-us/complaints-and-feedback" target={'_blank'}>Complaints and feedback</a></li>
                                <li><a href="https://www.servicesaustralia.gov.au/individuals/privacy" target={'_blank'}>Privacy</a></li>
                                <li><a href="https://www.servicesaustralia.gov.au/individuals/site-notices" target={'_blank'}>Site notices</a></li>
                                {/* eslint-enable react/jsx-no-target-blank */}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;

