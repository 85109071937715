import React, {Component} from 'react';
import Aux from '../Aux/aux';
import Header from './Header';
import Footer from './Footer';

class Page extends Component {
    render() {
        const {children} = this.props;

        return <Aux>
            <Header/>
            {children}
            <Footer/>
        </Aux>;
    }
}

export default Page;