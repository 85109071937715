import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';


class Header extends PureComponent {

    render() {

        return (
         <header>
             <div className="wrapper">
                 <div className="logo-holder">
                     <div className="logo-left">
                         <img src="/assets/img/logo_3.svg" alt="Australian Government logo" />
                     </div>
                     <div className="logo-center">
                         <Link to="/"><img src="/assets/img/logo.svg" alt="Service Connect" /></Link>
                     </div>
                 </div>
             </div>
         </header>
        );
    }
}

export default Header;

