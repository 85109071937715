import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import Home from './Components/Home/Home';
import PageNotFound404 from './Components/Pages/PageNotFound404.js';

import './Assets/scss/app.scss';

class App extends Component {
    render() {
        return (
            <Switch>
                <Route exact path='/' component={Home}/>
                <Route component={PageNotFound404}/>
            </Switch>
        );
    }
}

export default App;
