export function smoothScrollTo (coord, elem, scrollSpeed, endCallback) {
    if (!elem) {
        _animateScroll(coord, false, scrollSpeed, endCallback);
    } else {
        _animateScroll(coord, elem, scrollSpeed, endCallback);
    }
}

function _animateScroll (Y, element, _scrollSpeed, endCallback) {
    let scrollSpeed = (_scrollSpeed) ? _scrollSpeed : 10,
        screenY = (element)
            ? element.scrollTop
            : window.scrollY ? window.scrollY : document.documentElement.scrollTop;

    if (screenY < Y) {
        _scrollDown(Y, scrollSpeed, element, endCallback);
    }

    if (screenY > Y) {
        _scrollUp(Y, scrollSpeed, element, endCallback);
    }
}

function _scrollDown (Y, scrollSpeed, element, endCallback) {
    let targetScrollY = Y,
        screenY;

    screenY = (element)
        ? element.scrollTop
        : window.scrollY ? window.scrollY : document.documentElement.scrollTop;

    let scrollInterval = setInterval(function () {
        screenY = screenY + scrollSpeed;
        let nextScreenY = screenY + scrollSpeed;

        if (screenY > targetScrollY) {
            clearInterval(scrollInterval);

            if (endCallback && typeof endCallback === 'function') {
                endCallback();
            }

            return;
        }

        if (element) {
            element.scrollTop = (nextScreenY > targetScrollY)
                ? targetScrollY : screenY;
        } else {
            (nextScreenY > targetScrollY)
                ? window.scroll(0, targetScrollY)
                : window.scroll(0, screenY);
        }
    }, 5);
}

function _scrollUp (Y, scrollSpeed, element, endCallback) {
    let targetScrollY = Y,
        screenY;

    screenY = (element)
        ? element.scrollTop
        : window.scrollY ? window.scrollY : document.documentElement.scrollTop;

    let scrollInterval = setInterval(function () {
        screenY = screenY - scrollSpeed;
        let nextScreenY = screenY - scrollSpeed;

        if (screenY < targetScrollY) {
            clearInterval(scrollInterval);

            if (endCallback && typeof endCallback === 'function') {
                endCallback();
            }

            return;
        }

        if (element) {
            element.scrollTop = (nextScreenY < targetScrollY)
                ? targetScrollY : screenY;
        }  else {
            (nextScreenY < targetScrollY)
                ? window.scroll(0, targetScrollY)
                : window.scroll(0, screenY);
        }
    }, 5);
}

export function getOffsetTop (elem) {
    let offsetTop = 0;

    do {
        if (!isNaN( elem.offsetTop) )
        {
            offsetTop += elem.offsetTop;
        }
        // eslint-disable-next-line
    } while(elem = elem.offsetParent); //lint error in condition - don't change

    return offsetTop;
}