import React, {PureComponent} from 'react';
import Page from "../Page/Page";


class Page404 extends PureComponent {
    componentDidMount() {
        document.title = "404 Page not found - Service Connect";
    }

    render() {
        return (
            <Page>
                <div className="page-not-found">
                    <h1>404: Whoops!</h1>
                    <p><strong>We can’t find what you’re looking for!</strong></p>
                    <div>
                        <p>The link to the page is broken, or the page has been moved.</p>
                        <a href="/">Return to Homepage</a>
                    </div>
                </div>
            </Page>
        );
    }
}

export default Page404;
